// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.elevation-classes();
@include mat.app-background();

// Define the theme object.
$superapparel-theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: mat.$rose-palette,
      tertiary: mat.$red-palette,
    ),
    density: (
      scale: 0,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
:root {
  @include mat.all-component-themes($superapparel-theme);
}

// Comment out the line below if you want to use the pre-defined typography utility classes.
// For more information: https://material.angular.io/guide/typography#using-typography-styles-in-your-application.
// @include mat.typography-hierarchy($superapparel.online-theme);

// Comment out the line below if you want to use the deprecated `color` inputs.
// @include mat.color-variants-backwards-compatibility($superapparel.online-theme);
/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  box-sizing: border-box;
}

div.flex-spacer {
  flex: 1;
}

div.page-content,
a.page-content {
  width: 1400px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

div#mat-autocomplete-0 {
  margin-top: 4px;
}
[autofocus] {
  outline: none;
}
a {
  color: inherit;
  text-decoration: none;
}
